// @flow
import * as React from 'react'

import { Store } from 'Store.js'
import CostcoVoucher from 'components/CostcoVoucher'
import Rates from 'components/Rates'
import Cashback from 'components/Cashback'

import supportedThemes from './themes/__supportedThemes.js'
import { Currency } from 'i18n/NumberFormatter'
import { withTranslation } from 'react-i18next'
import cn from 'classnames'
import transDomain from './translations/index.translations'
import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'

function RegisterGroup (props) {
    const { t, theme } = props
    const { state } = React.useContext(Store)
    const currencyFormatter = Currency(state.currency, state.locale)
    const voucher = state.campaign.incentives.voucher
    const incentives = state.campaign.incentives.manufacturer
    const hiddenCashBack = incentives?.cashBack === 0

    return (
        <div className={cn(styles.container, {
            [styles.areCombinable]: incentives?.areCombinable,
            [styles.hiddenCashBack]: hiddenCashBack,
            [styles.withCostcoVoucher]: theme.withCostcoVoucher,
            [styles.withNoIncentives]: !Boolean(incentives),
        })}>
            <div className={styles.incentives}>
                <div className={styles.voucher} style={{
                    backgroundColor: theme.backgroundColor
                }}>
                    <h3 style={{
                        color: theme.textColor
                    }} className={styles.title}>
                        {t('title', {
                            province: state.campaign.client.address.province,
                            voucher: state.campaign.incentives.voucher.name,
                            type: state.campaign.incentives.voucher.type
                        })}
                    </h3>
                    <span style={{
                        color: theme.valueColor
                    }}>
                        {currencyFormatter.format(voucher.value)}
                    </span>
                </div>

                {theme.withCostcoVoucher && <CostcoVoucher />}
            </div>

           {Boolean(incentives) && (
                <div className={styles.cashIncentives}>
                    {Boolean(incentives.cashBack) && <div className={styles.cashbackWrapper}><Cashback /></div>}
                    {Boolean(incentives.interestRate) && (<div className={styles.ratesWrapper}><Rates /></div>)}
                </div>
           )}
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(RegisterGroup))
